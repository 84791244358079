import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import classNames from 'classnames';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import gameAction from '../media/quickAndEasy/game-action.png';
import gameAction2x from '../media/quickAndEasy/game-action.png';
import gameAction3x from '../media/quickAndEasy/game-action.png';
import collaboration from '../media/quickAndEasy/collaboration.png';
import collaboration2x from '../media/quickAndEasy/collaboration2x.png';
import collaboration3x from '../media/quickAndEasy/collaboration3x.png';
import brand from '../media/quickAndEasy/brand.png';
import brand2x from '../media/quickAndEasy/brand2x.png';
import brand3x from '../media/quickAndEasy/brand3x.png';
import easyBreezy from '../media/quickAndEasy/easy-breezy.png';
import easyBreezy2x from '../media/quickAndEasy/easy-breezy2x.png';
import easyBreezy3x from '../media/quickAndEasy/easy-breezy3x.png';

import styles from './MainFeaturesSection.module.scss';
import { Section } from '../../../components/Page';
import Image from '../../../components/Image';
import Grid from '../../../components/layout/Grid';
import Flex from '../../../components/layout/Flex';
import SectionHeading from '../../../components/SectionHeading';
import PartyGeese from '../../../media/geese/party-geese.svg';

type MainFeaturesImgType = 'realTime' | 'easyToPlay' | 'sharing' | 'branding';

const MainFeaturesSection = () => {
  const [activeImg, setActiveImg] = useState<MainFeaturesImgType>('realTime');

  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));

  const realTimeImg = (
    <Image
      className={styles.quickAndEasyImg}
      src={gameAction}
      srcSet={`${gameAction2x} 2x, ${gameAction3x} 3x`}
      width="646"
      height="526"
      alt="Activity feed & leaderboard"
    />
  );
  const realTimeTxt = (
    <div>
      <h3 className={styles.quickAndEasySubtitle}>Interaction, in real-time</h3>
      <p className={styles.desc}>
        Keep an eye on your flock with updates that help both creators and participants stay in the loop.
      </p>
      <BulletListItem>Live leaderboard and activity feed</BulletListItem>
      <BulletListItem>Award bonus points for outstanding submissions</BulletListItem>
      <BulletListItem>Reporting dashboard to measure success</BulletListItem>
    </div>
  );
  const easyToPlayImg = (
    <Image
      className={styles.quickAndEasyImg}
      src={easyBreezy}
      srcSet={`${easyBreezy2x} 2x, ${easyBreezy3x} 3x`}
      width="646"
      height="526"
      alt="Easy to play"
    />
  );
  const easyToPlayTxt = (
    <div>
      <h3 className={styles.quickAndEasySubtitle}>Easy to find and join</h3>
      <p className={styles.desc}>The Goosechase mobile app is great for kids 5 to 95+. Just download and go!</p>
      <BulletListItem>Play as a guest &ndash; no need to set-up an account!</BulletListItem>
      <BulletListItem>Join codes help participants easily find your Experience</BulletListItem>
      <BulletListItem>Send messages to your participants at any time</BulletListItem>
    </div>
  );
  const sharingAndCollabImg = (
    <Image
      className={styles.quickAndEasyImg}
      src={collaboration}
      srcSet={`${collaboration2x} 2x, ${collaboration3x} 3x`}
      width="646"
      height="526"
      alt="Sharing and collaboration"
    />
  );
  const sharingAndCollabTxt = (
    <div>
      <h3 className={styles.quickAndEasySubtitle}>Fly higher together</h3>
      <p className={styles.desc}>
        You and your team can get all wings on deck when building and running your Experiences.
      </p>
      <BulletListItem>Allow multiple people to co-edit Experiences</BulletListItem>
      <BulletListItem>Control account permissions across your organization</BulletListItem>
      <BulletListItem>Duplicate Experiences for recurring events</BulletListItem>
    </div>
  );

  const brandingImg = (
    <Image
      className={styles.quickAndEasyImg}
      src={brand}
      srcSet={`${brand2x} 2x, ${brand3x} 3x`}
      width="646"
      height="526"
      alt="Branding"
    />
  );

  const brandingTxt = (
    <div>
      <h3 className={styles.quickAndEasySubtitle}>Feature your brand</h3>
      <p className={styles.desc}>
        Generate honk-tacular exposure for your organization or your sponsors with custom branding options.
      </p>
      <BulletListItem>Bespoke in-app branding</BulletListItem>
      <BulletListItem>Build Missions to generate traffic to sponsor sites</BulletListItem>
      <BulletListItem>Easily share user-generated promotional content</BulletListItem>
    </div>
  );

  return (
    <Section omitOverflow>
      <SectionHeading title={['Fun to create.', 'And participate.']} renderImage={() => <PartyGeese width={318} />} />
      {isMobile && (
        <>
          <Grid className={styles.quickAndEasyRow} container alignItems="center">
            <Grid item xs={12} md="flex">
              <div>{realTimeImg}</div>
            </Grid>
            <Grid item xs={12} md="auto">
              <div className={styles.quickAndEasyText}>{realTimeTxt}</div>
            </Grid>
          </Grid>
          <Grid className={styles.quickAndEasyRow} container alignItems="center">
            <Grid item xs={12} md="flex">
              <div>{easyToPlayImg}</div>
            </Grid>
            <Grid item xs={12} md="auto">
              <div className={styles.quickAndEasyText}>{easyToPlayTxt}</div>
            </Grid>
          </Grid>
          <Grid className={styles.quickAndEasyRow} container alignItems="center">
            <Grid item xs={12} md="flex">
              <div>{sharingAndCollabImg}</div>
            </Grid>
            <Grid item xs={12} md="auto">
              <div className={styles.quickAndEasyText}>{sharingAndCollabTxt}</div>
            </Grid>
          </Grid>
          <Grid className={styles.quickAndEasyRow} container alignItems="center">
            <Grid item xs={12} md="flex">
              <div>{brandingImg}</div>
            </Grid>
            <Grid item xs={12} md="auto">
              <div className={styles.quickAndEasyText}>{brandingTxt}</div>
            </Grid>
          </Grid>
        </>
      )}

      {!isMobile && (
        <Flex alignItems="flex-start">
          <div className={styles.stickyImgColumn}>
            <div
              className={classNames(styles.quickAndEasyImgWrapper, {
                [styles.activeImgWrapper]: activeImg === 'realTime',
              })}
            >
              {realTimeImg}
            </div>
            <div
              className={classNames(styles.quickAndEasyImgWrapper, {
                [styles.activeImgWrapper]: activeImg === 'easyToPlay',
              })}
            >
              {easyToPlayImg}
            </div>
            <div
              className={classNames(styles.quickAndEasyImgWrapper, {
                [styles.activeImgWrapper]: activeImg === 'sharing',
              })}
            >
              {sharingAndCollabImg}
            </div>
            <div
              className={classNames(styles.quickAndEasyImgWrapper, {
                [styles.activeImgWrapper]: activeImg === 'branding',
              })}
            >
              {brandingImg}
            </div>
          </div>
          <div>
            <div className={styles.quickAndEasyText}>
              <div className={styles.quickAndEasyTextInnerFirst}>
                <Waypoint topOffset="48%" onEnter={() => setActiveImg('realTime')}>
                  {realTimeTxt}
                </Waypoint>
              </div>
            </div>

            <div className={styles.quickAndEasyText}>
              <div className={styles.quickAndEasyTextInner}>
                <Waypoint bottomOffset="48%" topOffset="48%" onEnter={() => setActiveImg('easyToPlay')}>
                  {easyToPlayTxt}
                </Waypoint>
              </div>
            </div>

            <div className={styles.quickAndEasyText}>
              <div className={styles.quickAndEasyTextInner}>
                <Waypoint bottomOffset="48%" topOffset="48%" onEnter={() => setActiveImg('sharing')}>
                  {sharingAndCollabTxt}
                </Waypoint>
              </div>
            </div>

            <div className={styles.quickAndEasyText}>
              <div className={styles.quickAndEasyTextInner}>
                <Waypoint bottomOffset="48%" topOffset="48%" onEnter={() => setActiveImg('branding')}>
                  {brandingTxt}
                </Waypoint>
              </div>
            </div>
          </div>
        </Flex>
      )}
    </Section>
  );
};

const BulletListItem = ({ children }: React.PropsWithChildren<{}>) => (
  <div className={styles.bulletListItem}>
    <div className={styles.bulletContainer}>
      <div className={styles.bullet} />
    </div>
    <p>{children}</p>
  </div>
);

export default MainFeaturesSection;
